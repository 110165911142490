import React, { useEffect } from 'react';
import ReactGA from 'react-ga4'
import { getUser } from 'ducks/auth/selectors';
import { useReduxSelector } from 'hooks';

const withTracker = (WrappedComponent, pathOverride = null, options = {}) => {
  const trackPage = (page, options) => {

    //console.log('GA Options: ', options);
    ReactGA.set({
      page,
      ...options
    });
    ReactGA.send({ hitType: "pageview", page: page})
  };

  const HOC = props => {
    const user = useReduxSelector(getUser);
    //console.log('user: ', user)

    useEffect(() => {
      if (user) {
        const { ac_id = '', firstname = '', lastname = '', email = '', phone = '', org = ''} = user;
        options = {
          user_properties: {
            user_acid:  ac_id, 
            user_name: firstname + ' ' + lastname,
            user_email: email,
            user_phone: phone, 
            user_org: org
          }
        }  
      }
    }, [user]);

    useEffect(() => trackPage(pathOverride || props.location.pathname, options), [
      props.location.pathname
    ]);
  
    return <WrappedComponent {...props} />;
  };

  return HOC;
};

export default withTracker;
